import { MapContainer, Marker, Popup, TileLayer } from "react-leaflet";
import "./App.css";
import { PrismicLink, useAllPrismicDocumentsByType } from "@prismicio/react";

function Markers() {
  const [documents] = useAllPrismicDocumentsByType("review");
  if (!documents) {
    return <></>;
  }
  return (
    <>
      {documents.map((document: any) => {
        return (
          <Marker
            position={[
              document.data.location.latitude,
              document.data.location.longitude,
            ]}
          >
            <Popup>
              <PrismicLink document={document}>
                {document.data.title[0].text}
              </PrismicLink>
            </Popup>
          </Marker>
        );
      })}
    </>
  );
}

function Map() {
  return (
    <>
      <MapContainer
        center={[59.315701291617884, 18.030227422714237]} //Current position
        zoom={13}
        scrollWheelZoom={false}
        id="map"
      >
        <TileLayer
          attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
          url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
        />
        <Markers />
      </MapContainer>
    </>
  );
}

export default Map;
