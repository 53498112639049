import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import "./App.css";
import Start from "./Start";
import Review from "./Review";

function App() {
  return (
    <Router>
      <Routes>
        <Route path={`/review/:id/`} element={<Review />} />
        <Route path="/" element={<Start />} />
      </Routes>
    </Router>
  );
}

export default App;
