import { PrismicRichText, usePrismicDocumentByUID } from "@prismicio/react";
import Map from './Map';

function Start(){
    const [document] = usePrismicDocumentByUID('start_page', 'start')
    if(!document){
      return <h1 className="margin">Loading...</h1>
    }
    return (<>
      <div className="margin">
        <PrismicRichText field={document.data.site_title}></PrismicRichText>
      </div>
      <Map/>
    </>)
      
}
export default Start;