import "./App.css";
import { PrismicImage, PrismicLink, PrismicRichText, useFirstPrismicDocument, usePrismicDocumentByUID, usePrismicDocumentsByType, useSinglePrismicDocument } from "@prismicio/react";
import { useParams } from 'react-router-dom';

function Review() {
    const { id } = useParams(); 
    const [document] = usePrismicDocumentByUID('review', id ? id : "")
    const [start] = useSinglePrismicDocument('start_page')
    if (!document || !start){
      return <h1 className="margin">Loading...</h1>
    }
    return (
      <div className="margin">
        <PrismicLink document={start}>{'💩 back to map 🚽'}</PrismicLink>
          <PrismicRichText field={document.data.title}/>
          {
            document.data.images.map(( image : any, key: any) => {
                return(
                    
                    <PrismicImage field={image.image} key={'image' + key}></PrismicImage> // Properly key this
                )
            })
          }
          <PrismicRichText field={document.data.body}/>
      </div>
    );
}

export default Review;

